export const adjectives = [
    `Amazing`,
    `Kind`,
    `Benevolent`,
    `Cool`,
    `Awesome`,
    `Sassy`,
    `Royal`,
    `Inspirational`,
    `Brave`,
    `Self-confident`,
    `Sensible`,
    `Sincere`,
    `Thoughtful`,
    `Warmhearted`,
    `Willing`,
    `Proficient`,
    `Romantic`,
    `Powerful`,
    `Persistent`,
    `Passionate`,
    `Loving`,
    `Faithful`,
    `Nice`,
    `Optimistic`,
    `Plucky`,
    `Philosophical`,
    `Humorous`,
    `Fearless`,
    `Considerate`,
    `Courageous`,
    `Marvelous`,
    `Capable`,
    `Accomplished`,
    `Knowledgeable`,
    `Adept`,
    `Expert`,
    `Engaging`
 ]

 export default { adjectives }
 