export const nouns = [
    `person`,
    `superstar`,
    `MVP`,
    `celebrity`,
    `luminary`,
    `headliner`,
    `achiever`,
    `aficionado`,
    `ambassador`,
    `artisan`,
    `artist`,
    `authority`,
    `being`,
    `boss`,
    `buddy`,
    `captain`,
    `champ`,
    `champion`,
    `co-worker`,
    `collaborator`,
    `colleague`,
    `commander`,
    `comrade`,
    `connoisseur`,
    `craftsperson`,
    `creator`,
    `dreamboat`,
    `dynamo`,
    `einstein`,
    `encourager`,
    `entertainer`,
    `enthusiast`,
    `entrepreneur`,
    `epicurean`,
    `exec`,
    `executive`,
    `exemplar`,
    `experimenter`,
    `expert`,
    `foodie`,
    `founder`,
    `friend`,
    `gem`,
    `genius`,
    `giver`,
    `godsend`,
    `go-getter`,
    `gorgeousness`,
    `grandmaster`,
    `guardian`,
    `guest`,
    `guide`,
    `guru`,
    `heavy hitter`,
    `hero`,
    `humanitarian`,
    `icon`,
    `innovator`,
    `inspiration`,
    `inventor`,
    `jester`,
    `joker`,
    `leader`,
    `legend`,
    `lionheart`,
    `maestro`,
    `magician`,
    `marvel`,
    `mastermind`,
    `mentor`,
    `miracle worker`,
    `model`,
    `motivator`,
    `neighbor`,
    `noble`,
    `optimist`,
    `original`,
    `originator`,
    `pacifist`,
    `pal`,
    `pathfinder`,
    `peacekeeper`,
    `performer`,
    `personality`,
    `phenomenon`,
    `philanthropist`,
    `philosopher`,
    `pioneer`,
    `poet`,
    `presence`,
    `president`,
    `pro`,
    `problem solver`,
    `prodigy`,
    `professional`,
    `protector`,
    `protégé`,
    `queen`,
    `romantic`,
    `sage`,
    `saint`,
    `savant`,
    `scholar`,
    `secret weapon`,
    `sensation`,
    `soul`,
    `specialist`,
    `star`,
    `straight shooter`,
    `strategist`,
    `stylist`,
    `sweetheart`,
    `talent`,
    `teacher`,
    `teammate`,
    `thinker`,
    `trailblazer`,
    `treasure`,
    `tycoon`,
    `valedictorian`,
    `vip`,
    `visionary`,
    `winner`,
    `wizard`
 ]

 export default { nouns }
 