import { random } from './random'

export const getPhrase = (adjectives, nouns) => {
    const adjective = random(...adjectives)
    const noun = random(...nouns)

    return `${adjective} ${noun}!`
  }

export default { getPhrase }
